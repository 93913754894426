<template>
    <div>
        <particles>
        </particles>


        <div class="login_black_hg">
            <div class="head_log" style="font-size:26px;margin-top:50px;">
                登录
            </div>
            <!--<div class="unline" style="margin-bottom:30px;"></div>-->
            <div class="form-group" style="margin-top:30px;">
                <el-input class="login_input"
                          size="small"
                          v-model="username"
                          @keyup.enter.native="login"
                          placeholder="用户名"
                ></el-input>
            </div>
            <div class="form-group">
                <el-input
                        type="password"
                        size="small"
                        v-model="password"
                        @keyup.enter.native="login"
                        class="login_input"
                        placeholder="密码"
                ></el-input>
            </div>

            <el-button size="" class="login_btn" @click="login" type="primary">登 录</el-button>
        </div>
        <div class="clear"></div>


    </div>


</template>

<script>
    import particles from '@/components/particles/index';

    export default {
        components: {
            particles
        },
        name: "login",
        data() {
            return {
                username: "",
                password: "",
                isCheck: true
            };
        },
        methods: {
            // 登录
            login: function () {
                // 重新赋值vm使 axios可用vue实例
                var vm = this;
                if (!this.isCheck) {
                    this.$message.error("请先认真阅读本站协议！");
                    return;
                }

                if (this.username == "" || this.password == "") {
                    this.$message.error("用户名和密码不能为空！");
                    return;
                }

                this.$post(this.$api.login, {
                    username: this.username,
                    password: this.password
                }).then(function (res) {
                    if (res.code == 200) {
                        // console.log(res);
                        // 存储用户的token
                        localStorage.setItem("token", res.token);
                        localStorage.setItem('user_info', JSON.stringify(res.user_info));
                        vm.$message({message: "登录成功！", type: "success"});
                        vm.$router.push({name: "admin_default"});
                    }
                });
            }
        },
        created: function () {
            /* var _this = this;
             // 判断token是否失效
             this.$get(this.$api.checkUserLogin).then(function (res) {
                 // console.log(res);
                 if (res.code == 200) {
                     _this.$router.push({name: "admin_default"});
                 }
             });*/
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .pass_input {
        float: left;
        width: 215px;
        margin-left: 10px;
    & /deep/ .el-input__inner {
          border-radius:0px;
          border: none;
          border-bottom:1px solid #DCDFE6;
      }
    }


    .login_black_hg {
        position: fixed;
        z-index: 999;
        background: #fff;
        /* width: 400px; */
        border-radius: 6px;
        padding: 40px 40px 20px 40px;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari */
        padding-top: 40px;
        left: 40%;
        top: 180px;
        padding-bottom:100px;
    }

    .login_hg_left {
        float: left;
        position: absolute;
        left: -180px;
        top: -390px;

    }

    .login_input {
        width:250px;
        margin: 10px 0 20px 0;
    & /deep/ .el-input__inner {
          border-radius:0px;
          border: none;
          border-bottom:1px solid #DCDFE6;
      }
    }


    .login_btn {
        margin-top: 20px;
        width: 100%;
        font-size: 14px;
    }

    .must_rad {
        font-size: 12px;
    }
</style>

